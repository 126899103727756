import { DialogService }            from 'aurelia-dialog';
import { bindable, inject }         from 'aurelia-framework';
import { I18N }                     from 'aurelia-i18n';
import { BaseOrderedListViewModel } from 'base-ordered-list-view-model';
import { FilterFormSchema }         from 'modules/map/management/manufacturers/filter-form-schema';
import { ReclassifyManufacturers }  from 'modules/map/management/manufacturers/reclassify/';
import { ManufacturersRepository }  from 'modules/map/management/manufacturers/services/repository';
import { AppContainer }             from 'resources/services/app-container';
import { Downloader }               from 'resources/services/downloader';

@inject(AppContainer, ManufacturersRepository, I18N, Downloader, FilterFormSchema, DialogService)
export class ListManufacturers extends BaseOrderedListViewModel {

    listingId = 'manufacturers-listing';

    @bindable headerTitle    = 'listing.map.management.manufacturers';
    @bindable newRecordRoute = 'map.management.manufacturers.create';
    @bindable repository;
    @bindable datatable;

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param i18N
     * @param downloader
     * @param filterFormSchema
     * @param dialogService
     */
    constructor(appContainer, repository, i18N, downloader, filterFormSchema, dialogService) {
        super(appContainer);

        this.repository       = repository;
        this.i18N             = i18N;
        this.downloader       = downloader;
        this.filterFormSchema = filterFormSchema;
        this.dialogService    = dialogService;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return this.appContainer.globalConfig.defaultListView;
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return this.redirectToSelectLotIntervention('map.management.manufacturers.index') && super.can([
            'map.management.manufacturers.manage',
            'map.management.manufacturers.view',
        ]);
    }

    /**
     * Handles activate event
     */
    async activate() {
        super.activate();

        this.defineDatatable();

        this.filterSchema = this.filterFormSchema.schema(this);
        this.filterModel  = this.filterFormSchema.model(this);
    }

    /**
     * Defines table columns
     */
    defineDatatable() {
        this.datatable = {
            repository:      this.repository,
            show:            {
                action:  (row) => this.appContainer.router.navigateToRoute('map.management.manufacturers.view', { id: row.id }),
                visible: (row) => this.appContainer.authenticatedUser.can(['map.management.manufacturers.manage', 'map.management.manufacturers.view']),
            },
            edit:            {
                action:  (row) => this.appContainer.router.navigateToRoute('map.management.manufacturers.edit', { id: row.id }),
                visible: (row) => this.appContainer.authenticatedUser.can(['map.management.manufacturers.manage', 'map.management.manufacturers.edit']),
            },
            destroy:         {
                action:  (row) => this.repository.destroy(row.id),
                visible: (row) => this.appContainer.authenticatedUser.can(['map.management.manufacturers.manage', 'map.management.manufacturers.delete']),
            },
            options:         [
                {
                    label:   'form.button.reclassify-manufacturers',
                    icon:    'icon-office',
                    action:  () => this.viewReclassify(),
                    visible: () => this.appContainer.authenticatedUser.can(['map.mat-app-proposals.manage', 'map.mat-app-proposals.edit']),
                },
            ],
            selectable:      true,
            destroySelected: true,
            actionsContextMenu: true,
            sorting:         {
                column:    0,
                direction: 'asc',
            },
            columns:         [
                {
                    data:  'name',
                    name:  'manufacturer_translations.name',
                    title: 'form.field.name',
                },
                {
                    data:       'status',
                    name:       'boolean_statuses.name',
                    searchable: false,
                    title:      'form.field.status',
                    type:       'label',
                },
                {
                    data:  'created_by',
                    name:  'users.name',
                    title: 'form.field.created-by',
                    type:  'userEntityTeamLot',
                },
                {
                    data:            'created_at',
                    name:            'manufacturers.created_at',
                    title:           'form.field.created-at',
                    valueConverters: [
                        {
                            name: 'dateTimeFormat',
                        },
                    ],
                },
            ],
        };
    }

    /**
     * Opens the application setting modal
     *
     */
    viewReclassify() {
        this.dialogService
            .open({
                viewModel: ReclassifyManufacturers,
            })
            .whenClosed((response) => this.datatable.instance.reload());
    }

}
