import { inject }                    from 'aurelia-framework';
import { ManufacturersRepository }   from 'modules/map/management/manufacturers/services/repository';
import { MatAppProposalsRepository } from 'modules/map/mat-app-proposals/services/repository';
import { ReclassifyManufacturer }    from 'modules/map/models/reclassify-manufacturer';

@inject(ManufacturersRepository, MatAppProposalsRepository)
export class FormSchema {

    /**
     * Constructor
     *
     * @param manufacturersRepository
     * @param mapRepository
     */
    constructor(manufacturersRepository, mapRepository) {
        this.manufacturersRepository = manufacturersRepository;
        this.mapRepository           = mapRepository;
    }

    /**
     * Returns a new instance of the model
     *
     * @returns {ReclassifyManufacturer}
     */
    model() {
        return new ReclassifyManufacturer();
    }

    /**
     * Returns schema
     *
     * @param viewModel
     */
    schema(viewModel) {

        this.other_manufacturer = {
            type:         'multiselect-native',
            key:          'other_manufacturer',
            label:        'form.field.other-manufacturer',
            remoteSource: () => this.mapRepository.otherManufacturers(),
            size:         12,
        };

        this.manufacturer_id = {
            type:         'select2',
            key:          'manufacturer_id',
            label:        'form.field.manufacturer',
            remoteSource: () => this.manufacturersRepository.all(),
            size:         12,
        };

        return [
            [this.other_manufacturer], [this.manufacturer_id],
        ];
    }
}
