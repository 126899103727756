import { inject }                    from 'aurelia-framework';
import { SuppliersRepository }   from 'modules/map/management/suppliers/services/repository';
import { MatAppProposalsRepository } from 'modules/map/mat-app-proposals/services/repository';
import { ReclassifySupplier }    from 'modules/map/models/reclassify-supplier';

@inject(SuppliersRepository, MatAppProposalsRepository)
export class FormSchema {

    /**
     * Constructor
     *
     * @param suppliersRepository
     * @param mapRepository
     */
    constructor(suppliersRepository, mapRepository) {
        this.suppliersRepository = suppliersRepository;
        this.mapRepository           = mapRepository;
    }

    /**
     * Returns a new instance of the model
     *
     * @returns {ReclassifySupplier}
     */
    model() {
        return new ReclassifySupplier();
    }

    /**
     * Returns schema
     *
     * @param viewModel
     */
    schema(viewModel) {

        this.other_supplier = {
            type:         'multiselect-native',
            key:          'other_supplier',
            label:        'form.field.other-supplier',
            remoteSource: () => this.mapRepository.otherSuppliers(),
            size:         12,
        };

        this.supplier_id = {
            type:         'select2',
            key:          'supplier_id',
            label:        'form.field.supplier',
            remoteSource: () => this.suppliersRepository.all(),
            size:         12,
        };

        return [
            [this.other_supplier], [this.supplier_id],
        ];
    }
}
